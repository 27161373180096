.root{
  position:absolute;
  display:flex;
  align-items:center;
  flex-wrap:nowrap;
  white-space:nowrap;
  background-color:#fff;
  padding:6px 12px;
  font-size:12px;
  line-height:18px;
  box-shadow:0px 16px 20px rgba(0,0,0,.04),0px 26px 36px 4px rgba(0,0,0,.02),0px 1px 12px -2px rgba(0,0,0,.06);
  border-radius:2px;
  z-index:20;
  --tw-text-opacity:1;
  color:rgba(31, 33, 41, var(--tw-text-opacity))
}

@media (min-width: 1680px){
  .root{
    font-size:14px;
    line-height:22px
  }
}

.root .current{
  padding:0 6px;
  background:rgba(0,168,96,.1);
  border:1px solid rgba(42,173,116,.16);
  border-radius:2px;
  margin:0px 12px 0 0;
  font-size:12px;
  line-height:18px;
  color:#00a860
}

.root .model-item{
  position:relative;
  display:inline-flex;
  align-items:center;
  border-bottom:1px dashed currentColor;
  --tw-text-opacity:1;
  color:rgba(151, 154, 168, var(--tw-text-opacity))
}

.root .model-item:hover{
  --tw-text-opacity:1;
  color:rgba(230, 88, 0, var(--tw-text-opacity))
}

.root .model-item.is-active{
  --tw-text-opacity:1;
  color:rgba(31, 33, 41, var(--tw-text-opacity))
}

.root .model-item.is-active:hover{
  --tw-text-opacity:1;
  color:rgba(31, 33, 41, var(--tw-text-opacity))
}

.root .model-item:hover .tips-content{
  display:block
}

.root .model-item .tips-content{
  display:none;
  position:absolute;
  width:233px;
  padding:12px;
  margin-bottom:10px;
  white-space:normal;
  background:rgba(0,0,0,.72);
  border-radius:4px;
  bottom:100%;
  left:50%;
  transform:translateX(-50%);
  font-size:14px;
  line-height:20px;
  color:#fff;
  cursor:default
}

.root .model-item .tips-content::before{
  position:absolute;
  top:100%;
  left:50%;
  margin:0 0 2px -6px;
  content:"";
  width:0;
  height:0;
  border-left:6px solid transparent;
  border-right:6px solid transparent;
  border-top:8px solid rgba(0,0,0,.72)
}

.root .model-item .tips-content::after{
  position:absolute;
  content:"";
  top:100%;
  left:0;
  width:100%;
  height:14px
}

.root .model-item .tips-content .tips-login{
  color:#ffcc32;
  cursor:pointer
}

.root .trigger{
  display:inline-flex;
  align-items:center;
  cursor:pointer
}

.root .trigger :global(.DCD_Icon){
  margin-right:4px
}