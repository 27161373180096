.dropdown-item{
  text-align:center;
  min-height:54px;
  line-height:54px;
  position:relative;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none
}

.dropdown-item .user-wrapper{
  min-height:54px;
  display:flex;
  flex-direction:row;
  background:#f8f8f8;
  padding:12px 0 12px 16px
}

.dropdown-item .user-wrapper .username-wrapper{
  flex:1;
  width:100%;
  padding:0 16px 0 12px;
  text-align:left;
  line-height:36px
}

.dropdown-item span{
  display:flex;
  justify-content:flex-start;
  align-items:center;
  padding-left:20px
}

.ic-logout{
  display:inline-block;
  width:16px;
  height:16px;
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABOpJREFUaAXVWj1PHEkQnTG2BBFISHyJgJM3Q7Ylg87ZhXc6OXJmO/BeCpLvD5xD/wNLtsNbBwehUxxehgRIJ0SGJQLECiI4hCBA2qtXdI1qeqt7e3Z3Dnuk2e6uj1evpqune1nyLMtG6Pav3BcY4xQbwy1J1EmwYpu7vuHy8vLDTqfzhORzvu6Wx0d5nm9ubW3tOh78APHBM7C4uDg+Ojr6jsYvb5lor/B/XV1dvd7b2/sXhjID+XdCHpxfEle0TXzwDCwtLT2i/jYEdKG21uj+ikHCNcy1EKv9BnF5TvdN6eT5Y5QTZgCCH+mWa217e5uzI0GMXEwnWIO0XcnQgwbeC3y4dbp7BwO69IKVJx8iCHlIx2BD+rBi7Cts5ixrQMm5azlDEZL7/im2XU/YAEG8qF0oAQMriXy/CcZICqZlk6cmICBWYpD10of8RK79LaLBGCkJaHAJKG1Jt7CwkE9MTPwC5enp6cbBwUGIjPhbLTBDfqV4cJZFbAHFZADqAgP5kZGRz7gnJyc/IqEYSERn4lv2nABt0cXq1n2LZEBmYTeRxPz8PGIIIWkte0vW8wFwCV1eXq6PjY0xAPoOyXK2ZEVglA2RbpGg6YTN6elpdFcODw91Wfg4Wudciwa2QT0SyOlcAQPsvnL5ASC3ZCV7qvns+vp6xZFuOmUoCfFFq7EtslYS7GMtYg0mQSyZH5ht3ZPuJwk/lp+IxSH3F7FpJMiqhZ1lyyZI4vj4eIUGLeWDmfgwNzfnx1QmpW4QX1ulgmmflH5OSWTtdnuVjEtJzM7OvndJgGAvkr30xXE6hZTYxEBLuqOjI5TBKpGGb7Em3HjV6cXHLxmJF231DAiQdvBl/jhmyzqQjMyExtP9nrhioBMQWT9tKDhjDSGJIKcqCYRIhuSloAMmEYzBr9FGo3FnfHwc33YapahqQF8ggiDKrGeXcA7J6B/a8fEtEFeTFvUP1P7q1gQLUz84AUf+z5gTBYypB9JRUj/NzMy8pQT+cEAI5i9qk0CVEhqIZF3OPANnZ2frNAuIYZbQgOVjPbmnqoQy6v9Nb6o3XpLWLHgm2c0Xmv39fUxX7CxkkbBkXQG0gHbinE6n70km9Q91i0pH9gRtntSvs4RKCSryrxSzFvaIfhavYNSZgMTIAuQ/VSVvlTKvgSJSDZ0QeTorrdKBz3/TVGZQ6wzUTR7Z1pbA/0EeCdRSQrSz5jg2E75esJ8GLRt63XaV3NBnoAL5LjJ4olUvngE6C+XuOJFhU3P7Quk1mAKsyDeVvfXkh0IeMTgBfRZyO7Le1BSXUhckSklOTU39TDJNnt/zfb5tUpLsSAk1FDXdF3EKmNhKO/AmJUCxlmcgZlBFd3Jy8oVm4Rl80B9gh7UemCUr3kKWErJSiRjJlGxAmO4Nw642kZRQSgArSfiF5CmYlo2FZ8nYt0oCVjCRBQOIQWJbGadXAj6gP9a8YjptF+qH/ENyxpEE2gr1vupb3RggdDF9CC/VR3NjzvIW2lTIz92vgfJjn1LddK1jbZfREATe0QHk8YcHvogDc8Zb5h4kRLpFDf+EifE3fuGn4N/AUUoou7i4eE3jlB0Yfrd5rZ2fn//uCPDfelBGxfueZuIBjfv+Z49+yssrldDDaaNsdnZ25J89eN2AeCkBz7tITMktmVJ3dS371EULMMu2kP0HV0qUUK+ArEEAAAAASUVORK5CYII=) 0 0/100% 100% no-repeat;
  margin-right:16px
}

.login-btn{
  width:60px;
  height:36px;
  border-radius:4px;
  background-color:#ffcc32;
  cursor:pointer;
  font-weight:500;
  font-size:12px;
  --tw-text-opacity:1;
  color:rgba(31, 33, 41, var(--tw-text-opacity))
}

@media (min-width: 1440px){
  .login-btn{
    font-size:14px
  }
}

.login-link:hover{
  color:#1f2129
}

.vertical-line{
  display:inline-block;
  width:1px;
  height:12px;
  background:#666;
  margin:0 8px
}

.dropdown-panel{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity:1;
  color:rgba(31, 33, 41, var(--tw-text-opacity));
  width:235px
}