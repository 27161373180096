@import '@byted-motor/pc-components/dist/styles/variable.scss';
.side-nav-switch {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.arrow {
  width: 0;
  height: 0;
  position: absolute;
  right: 2px;
  bottom: 3px;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  border-bottom: 4px solid #1f2129;
  transition: transform 0.2s ease-in-out;
  &:global(.on) {
    transform: rotate(270deg);
  }
  &:global(.off),
  &:global(.md-off),
  &:global(.lg-off) {
    transform: rotate(90deg);
  }
  &:global(.none),
  &:global(.md-none),
  &:global(.lg-none) {
    transform: rotate(180deg);
  }
  &:global(.fixed) {
    transform: rotate(0);
  }
}

@media (min-width: 1440px) {
  .arrow {
    &:global(.md-none),
    &:global(.md-off) {
      transform: rotate(270deg);
    }
  }
}
@media (min-width: 1680px) {
  .arrow {
    &:global(.lg-none),
    &:global(.lg-off) {
      transform: rotate(270deg);
    }
  }
}