@import '@byted-motor/pc-components/dist/styles/variable.scss';
.root {
  display: inline-block;
  min-width: 64px;
  height: 28px;
  line-height: 28px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  outline: 0;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;

}

.loading {
  cursor: wait;
}

.disabled {
  cursor: not-allowed;
}

.new_primary {
  color: #1a1a1a;
  background-color: #ffcc32;
  border-radius: 2px;
}

.new_primary.disabled {
  color: #999 !important;
  background: #e6e6e6 !important;
}

.new_primary:hover {
  background-color: #ffd65b;
}

.new_primary:active {
  background-color: #ffd000;
}

.primary {
  color: #1a1a1a;
  background-color: #ffe100;
}

.primary.disabled {
  color: #999 !important;
  background: #e6e6e6 !important;
}

.primary:hover {
  background-color: #ffe942;
}

.primary:active {
  background-color: #e5b72c;
}

.default {
  border: solid 1px #1a1a1a;
}

.default.disabled,
.highlight.disabled {
  color: #C9CBD6 !important;
  border: solid 1px #F2F4FA !important;
  background: #F2F4FA !important;
}

.default:hover {
  color: #1f2129;
  border: solid 1px #406599;
  background-color: white;
}

.default:active {
  color: #33507a;
  border: solid 1px #33507a;
  background-color: rgba(51, 80, 122, 0.1);
}

.highlight {
  border: solid 1px #ff9100;
  color: #ff9100;
}

.highlight:hover {
  border: solid 1px #ff9100;
  background-color: rgba(255, 145, 0, 0.1);
}

.highlight:active {
  color: #cc7400;
  border: solid 1px #cc7400;
  background-color: rgba(204, 116, 0, 0.1);
}