@import '@byted-motor/pc-components/dist/styles/variable.scss';
.header {
  color: #1f2129;
  position: relative;
  box-sizing: content-box;
  will-change: padding-top;
  transition: padding-top 0.6s;
}

.header-fix {
  position: fixed;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 1920px;
  min-width: 1024px;
  z-index: 9999;
  will-change: top;
  transition: top 0.6s;
  @apply tw-inset-0 tw-mx-auto;

  &.fire-fox {
    background-color: rgba(255, 255, 255, 1);
  }

  &:global(.theater) {
    background: #1f2129;
    color: #979aa8;
    .dch-link:hover {
      color: #fff;
    }
  }

  &:global(.theater) :global(.search-form) {
    border: none;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.08);
  }

  &:global(.theater) :global(.arrow) {
    border-bottom: 4px solid #fff;
  }
}

.vrcode-container {
  width: 200px;
  height: 236px;
  text-align: center;
}
.vrcode {
  width: 160px;
  height: 160px;
  margin: 20px;
}

.switch-wrap {
  @apply tw-absolute tw-z-2 tw-left-24 tw-inset-y-0 tw-flex tw-items-center;
}

.logo {
  @apply tw-h-32 tw-w-auto lg:tw-h-36 xl:tw-h-46;
}

.city {
  @apply tw-ml-38 lg:tw-ml-40 xl:tw-ml-38;
}

.dch-link {
  @apply tw-inline-block tw-text-center tw-mr-24 md:tw-mr-40 tw-leading-20 tw-cursor-pointer;
  &:hover {
    color: #e55800;
  }
}

%dch-icon {
  @apply tw-text-14 xl:tw-text-18 xl:tw-mr-4 tw-align-middle;
}

.dch-icon {
  @extend %dch-icon;
}

.add-icon {
  @apply tw-transform tw-rotate-45 tw-inline-block;
  @extend %dch-icon;
}

.dch-text {
  @apply tw-block xl:tw-inline-block tw-align-middle tw-text-12 xl:tw-text-14;
}

.publish-item {
  display: block;
  width: 110px;
  line-height: 42px;
  padding: 0 16px;
  &:hover {
    background-color: #FFCC321F;
    color: #e55800;
  }
}

.leave {
  animation: leave-frames var(--duration) ease forwards;
}
@keyframes leave-frames {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
.enter {
  animation: enter-frames var(--duration) ease forwards;
}
@keyframes enter-frames {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}