.header{
  color:#1f2129;
  position:relative;
  box-sizing:content-box;
  will-change:padding-top;
  transition:padding-top .6s
}

.header-fix{
  position:fixed;
  -webkit-backdrop-filter:blur(10px);
          backdrop-filter:blur(10px);
  background-color:rgba(255,255,255,.9);
  max-width:1920px;
  min-width:1024px;
  z-index:9999;
  will-change:top;
  transition:top .6s;
  margin-left:auto;
  margin-right:auto;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px
}

.header-fix.fire-fox{
  background-color:#fff
}

.header-fix:global(.theater){
  background:#1f2129;
  color:#979aa8
}

.header-fix:global(.theater) .dch-link:hover{
  color:#fff
}

.header-fix:global(.theater) :global(.search-form){
  border:none;
  color:#fff;
  background-color:rgba(255,255,255,.08)
}

.header-fix:global(.theater) :global(.arrow){
  border-bottom:4px solid #fff
}

.vrcode-container{
  width:200px;
  height:236px;
  text-align:center
}

.vrcode{
  width:160px;
  height:160px;
  margin:20px
}

.switch-wrap{
  display:flex;
  align-items:center;
  position:absolute;
  top:0px;
  bottom:0px;
  left:24px;
  z-index:2
}

.logo{
  height:32px;
  width:auto
}

@media (min-width: 1440px){
  .logo{
    height:36px
  }
}

@media (min-width: 1680px){
  .logo{
    height:46px
  }
}

.city{
  margin-left:38px
}

@media (min-width: 1440px){
  .city{
    margin-left:40px
  }
}

@media (min-width: 1680px){
  .city{
    margin-left:38px
  }
}

.dch-link{
  cursor:pointer;
  display:inline-block;
  line-height:20px;
  margin-right:24px;
  text-align:center
}

@media (min-width: 1280px){
  .dch-link{
    margin-right:40px
  }
}

.dch-link:hover{
  color:#e55800
}

.add-icon, .dch-icon{
  font-size:14px;
  vertical-align:middle
}

@media (min-width: 1680px){
  .add-icon, .dch-icon{
    font-size:18px;
    margin-right:4px
  }
}

.add-icon{
  display:inline-block;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-rotate:45deg
}

.dch-text{
  display:block;
  font-size:12px;
  vertical-align:middle
}

@media (min-width: 1680px){
  .dch-text{
    display:inline-block;
    font-size:14px
  }
}

.publish-item{
  display:block;
  width:110px;
  line-height:42px;
  padding:0 16px
}

.publish-item:hover{
  background-color:#FFCC321F;
  color:#e55800
}

.leave{
  animation:leave-frames var(--duration) ease forwards
}

@keyframes leave-frames{
  from{
    transform:translateY(0)
  }

  to{
    transform:translateY(-100%)
  }
}

.enter{
  animation:enter-frames var(--duration) ease forwards
}

@keyframes enter-frames{
  from{
    transform:translateY(-100%)
  }

  to{
    transform:translateY(0)
  }
}