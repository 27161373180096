/* stylelint-disable */
.emoji {
    display: inline-block;
    vertical-align: text-bottom;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: calc(1em + 3px);
    padding-left: calc(1em + 3px);
}

.emoji-name {
    display: inline-block;
    font-size: 0;
    width: 2px;
    vertical-align: middle
}

.emoji_1_smile {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_1_smile@3x.png)
}

.emoji_2_kiss {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_2_kiss@3x.png)
}

.emoji_3_daze {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_3_daze@3x.png)
}

.emoji_4_smoke {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_4_smoke@3x.png)
}

.emoji_5_pick_nose {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_5_pick_nose@3x.png)
}

.emoji_6_cry {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_6_cry@3x.png)
}

.emoji_7_anger {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_7_anger@3x.png)
}

.emoji_8_laugh {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_8_laugh@3x.png)
}

.emoji_9_sleep {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_9_sleep@3x.png)
}

.emoji_10_shy {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_10_shy@3x.png)
}

.emoji_11_naughty {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_11_naughty@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 22px
}

.emoji_12_dizzy {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_12_dizzy@3x.png)
}

.emoji_13_stunned {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_13_stunned@3x.png)
}

.emoji_14_shut_up {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_14_shut_up@3x.png)
}

.emoji_15_wit {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_15_wit@3x.png)
}

.emoji_16_attention {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_16_attention@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 23px
}

.emoji_17_hand_sample {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_17_hand_sample@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 22px
}

.emoji_18_ye {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_18_ye@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 21px
}

.emoji_19_distress_situation {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_19_distress_situation@3x.png)
}

.emoji_20_play_face {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_20_play_face@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 22px
}

.emoji_21_smile {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_21_smile@3x.png)
}

.emoji_22_yawn {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_22_yawn@3x.png)
}

.emoji_23_surprise {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_23_surprise@3x.png)
}

.emoji_24_take_heart {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_24_take_heart@3x.png)
}

.emoji_25_sleepy {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_25_sleepy@3x.png)
}

.emoji_26_what {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_26_what@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 22px
}

.emoji_27_sobbing {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_27_sobbing@3x.png)
}

.emoji_28_handclap {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_28_handclap@3x.png)
}

.emoji_29_cool {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_29_cool@3x.png)
}

.emoji_30_titter {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_30_titter@3x.png)
}

.emoji_31_lightning_strike {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_31_lightning_strike@3x.png)
}

.emoji_32_meditation {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_32_meditation@3x.png)
}

.emoji_33_vomiting_blood {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_33_vomiting_blood@3x.png)
}

.emoji_34_acting_cute {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_34_acting_cute@3x.png)
}

.emoji_35_quietly {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_35_quietly@3x.png)
}

.emoji_36_hum {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_36_hum@3x.png)
}

.emoji_37_khan {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_37_khan@3x.png)
}

.emoji_38_forced_smile {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_38_forced_smile@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 24px
}

.emoji_39_haze {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_39_haze@3x.png)
}

.emoji_40_smirk {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_40_smirk@3x.png)
}

.emoji_41_cool {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_41_cool@3x.png)
}

.emoji_42_bad_smile {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_42_bad_smile@3x.png)
}

.emoji_43_crazy {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_43_crazy@3x.png)
}

.emoji_44_tears {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_44_tears@3x.png)
}

.emoji_45_money {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_45_money@3x.png)
}

.emoji_46_kiss {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_46_kiss@3x.png)
}

.emoji_47_fear {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_47_fear@3x.png)
}

.emoji_48_smile {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_48_smile@3x.png)
}

.emoji_49_injustice {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_49_injustice@3x.png)
}

.emoji_50_their {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_50_their@3x.png)
}

.emoji_51_focus {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_51_focus@3x.png)
}

.emoji_52_like {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_52_like@3x.png)
}

.emoji_53_handclap {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_53_handclap@3x.png)
}

.emoji_54_3Q {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_54_3Q@3x.png)
}

.emoji_55_kiss {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_55_kiss@3x.png)
}

.emoji_56_household_cleanser {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_56_household_cleanser@3x.png)
}

.emoji_57_666 {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_57_666@3x.png)
}

.emoji_58_rose {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_58_rose@3x.png)
}

.emoji_59_cucumber {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_59_cucumber@3x.png)
}

.emoji_60_beer {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_60_beer@3x.png)
}

.emoji_61_distress_situation {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_61_distress_situation@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 22px
}

.emoji_62_injustice {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_62_injustice@3x.png)
}

.emoji_63_lick_screen {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_63_lick_screen@3x.png)
}

.emoji_64_despise {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_64_despise@3x.png)
}

.emoji_65_kiss {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_65_kiss@3x.png)
}

.emoji_66_bye {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_66_bye@3x.png)
}

.emoji_67_erkang {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_67_erkang@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 23px
}

.emoji_68_hug {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_68_hug@3x.png);
    background-size: auto 20px;
    padding-top: 20px;
    padding-left: 23px
}

.emoji_69_hitler {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_69_hitler@3x.png)
}

.emoji_70_zhoudongyu_gaze {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_70_zhoudongyu_gaze@3x.png)
}

.emoji_71_masichun_smile {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_71_masichun_smile@3x.png)
}

.emoji_72_dogo {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_72_dogo@3x.png)
}

.emoji_73_cat {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_73_cat@3x.png)
}

.emoji_74_2ha {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_74_2ha@3x.png)
}

.emoji_75_dogo {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_75_dogo@3x.png)
}

.emoji_76_bear {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_76_bear@3x.png)
}

.emoji_77_skeleton {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_77_skeleton@3x.png)
}

.emoji_78_black_face {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_78_black_face@3x.png)
}

.emoji_79_eat_melon {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_79_eat_melon@3x.png)
}

.emoji_80_green_hat {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_80_green_hat@3x.png)
}

.emoji_81_sweat {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_81_sweat@3x.png)
}

.emoji_82_lear_ropes {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_82_lear_ropes@3x.png)
}

.emoji_83_frown {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_83_frown@3x.png)
}

.emoji_84_wipe_sweat {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_84_wipe_sweat@3x.png)
}

.emoji_85_redface {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_85_redface@3x.png)
}

.emoji_86_orz {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_86_orz@3x.png)
}

.emoji_87_makeface {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_87_makeface@3x.png)
}

.emoji_88_intensity {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_88_intensity@3x.png)
}

.emoji_89_ruhua {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_89_ruhua@3x.png)
}

.emoji_90_spit {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_90_spit@3x.png)
}

.emoji_91_surprise {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_91_surprise@3x.png)
}

.emoji_92_knock {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_92_knock@3x.png)
}

.emoji_93_refuel {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_93_refuel@3x.png)
}

.emoji_94_rainbow {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_94_rainbow@3x.png)
}

.emoji_95_bigcry {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_95_bigcry@3x.png)
}

.emoji_96_fingerheart {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_96_fingerheart@3x.png)
}

.emoji_97_refuel {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_97_refuel@3x.png)
}

.emoji_98_fist {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_98_fist@3x.png)
}

.emoji_99_OK {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_99_OK@3x.png)
}

.emoji_100_highfive {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_100_highfive@3x.png)
}

.emoji_101_left {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_101_left@3x.png)
}

.emoji_102_shakehand {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_102_shakehand@3x.png)
}

.emoji_103_18 {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_103_18@3x.png)
}

.emoji_104_knife {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_104_knife@3x.png)
}

.emoji_105_V5 {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_105_V5@3x.png)
}

.emoji_106_awesome {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_106_awesome@3x.png)
}

.emoji_107_heart {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_107_heart@3x.png)
}

.emoji_108_broken_heart {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_108_broken_heart@3x.png)
}

.emoji_109_shit {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_109_shit@3x.png)
}

.emoji_110_gifts {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_110_gifts@3x.png)
}

.emoji_111_cake {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_111_cake@3x.png)
}

.emoji_112_flower {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_112_flower@3x.png)
}

.emoji_113_lookat {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_113_lookat@3x.png)
}

.emoji_114_bomb {
    background-image: url(http://lf3-cdn-tos.bytegoofy.com/goofy/toutiao/tt_tps/static/images/ttemoji_v2/emoji_114_bomb@3x.png)
}

/* stylelint-enable */
