@import '@byted-motor/pc-components/dist/styles/variable.scss';
.avatar-img {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  border-radius: 50%;
}
.avatar-img :global(img) {
  background: #e6e8f2;
  border: 1px solid #fff;
}
.author-v {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}