@font-face {
  font-family: 'icomoon';
  font-display: swap;
  src: url('//lf3-motor.dcarstatic.com/obj/ttfe/motor/pc/icomoon.woff?ogi3n2');
  src: url('//lf3-motor.dcarstatic.com/obj/ttfe/motor/pc/icomoon.eot?ogi3n2#iefix') format('embedded-opentype'), url('//lf3-motor.dcarstatic.com/obj/ttfe/motor/pc/icomoon.ttf?ogi3n2') format('truetype'), url('//lf3-motor.dcarstatic.com/obj/ttfe/motor/pc/icomoon.woff?ogi3n2') format('woff'), url('//lf3-motor.dcarstatic.com/obj/ttfe/motor/pc/icomoon.svg?ogi3n2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-location-ic:before {
  content: "\e909";
}
.icon-arrow-ic-r:before {
  content: "\e900";
}
.icon-auto-icon-unselect:before {
  content: "\e901";
}
.icon-code-ic:before {
  content: "\e902";
}
.icon-delete-ic:before {
  content: "\e903";
}
.icon-feedback-ic:before {
  content: "\e904";
}
.icon-icon-credentials:before {
  content: "\e905";
}
.icon-icon-description:before {
  content: "\e906";
}
.icon-icon-up:before {
  content: "\e907";
}
.icon-invalid-name:before {
  content: "\e908";
}
.icon-next-arrow-s:before {
  content: "\e90a";
}
.icon-next-arrow:before {
  content: "\e90b";
}
.icon-oval-ic:before {
  content: "\e90c";
}
.icon-oval:before {
  content: "\e90d";
}
.icon-pk:before {
  content: "\e90e";
}
.icon-search-ic:before {
  content: "\e90f";
}
.icon-up-arrow-ic:before {
  content: "\e910";
}
.icon-dislike-ic:before {
  content: "\e911";
}
.icon-dislike-ic:hover {
  color: #406599;
  cursor: pointer;
}
