.root{
  display:flex;
  align-items:center;
  justify-content:center;
  width:36px;
  height:16px;
  line-height:16px;
  background:#e65800;
  border-radius:10px 2px;
  color:#fff
}

@media (min-width: 1280px){
  .root{
    width:40px
  }
}

.root .text{
  font-size:16px;
  transform:scale(0.5);
  white-space:nowrap
}