.root{
  width:160px;
  background-color:#f7f8fc
}

.root .fixed-wrapper{
  position:fixed;
  width:160px;
  will-change:padding-top;
  transition:padding-top .6s;
  bottom:0;
  box-sizing:content-box;
  z-index:1999
}

.root .scroll-wrapper{
  position:absolute;
  z-index:10;
  width:100%;
  height:100%;
  left:0;
  overflow-x:auto;
  overflow-y:scroll;
  -ms-scroll-chaining:none;
      overscroll-behavior:none;
  background-color:#f7f8fc;
  padding-top:6px
}

.root .list{
  padding:0 6px;
  width:160px
}

.root .list .list-item:last-child .item-link{
  border-top:1px solid #e6e8f2
}

.root .list .list-item .item-link{
  position:relative;
  display:flex;
  flex-wrap:nowrap;
  padding:12px 0 12px 24px;
  align-items:center;
  border-radius:4px;
  font-size:14px;
  line-height:22px;
  color:#1f2129
}

@media (min-width: 1680px){
  .root .list .list-item .item-link{
    font-size:16px;
    line-height:24px
  }
}

.root .list .list-item .item-link:hover{
  background-color:rgba(255,204,50,.12);
  --tw-text-opacity:1;
  color:rgba(229, 88, 0, var(--tw-text-opacity))
}

.root .list .list-item .item-link.is-active{
  background:linear-gradient(250deg, #FFD861 -0.65%, #FFCC32 100%)
}

.root .list .list-item .item-link.has-children{
  border-top:1px solid #e6e8f2;
  border-radius:0;
  background-color:#f7f8fc;
  cursor:default;
  --tw-text-opacity:1;
  color:rgba(151, 154, 168, var(--tw-text-opacity))
}

.root .list .list-item .item-link.has-children .link-icon,.root .list .list-item .item-link.has-children .link-arrow{
  display:none
}

.root .list .list-item .item-link.has-children .link-name{
  font-size:16px;
  line-height:24px;
  color:#979aa8
}

.root .list .list-item .item-link .link-icon{
  display:inline-flex;
  align-items:center;
  width:18px;
  height:18px;
  font-size:18px;
  margin-right:12px
}

.root .list .list-item .item-link .link-name{
  flex:1;
  display:inline-flex;
  align-items:center;
  justify-content:space-between;
  white-space:nowrap;
  position:relative
}

.root .list .list-item .item-link .link-name .link-arrow{
  margin-right:18px;
  font-size:12px;
  line-height:12px
}

@media (min-width: 1680px){
  .root .list .list-item .item-link .link-name .link-arrow{
    font-size:14px;
    line-height:14px
  }
}

.root .list .list-item .item-link .rank-flag{
  position:absolute;
  bottom:100%;
  left:0
}

.root .child-list .child-link{
  display:flex;
  flex-wrap:nowrap;
  padding:12px 0 12px 24px;
  align-items:center;
  border-radius:4px;
  font-size:14px;
  line-height:22px;
  color:#1f2129
}

@media (min-width: 1680px){
  .root .child-list .child-link{
    font-size:16px;
    line-height:24px
  }
}

.root .child-list .child-link:hover{
  background-color:rgba(255,204,50,.12);
  --tw-text-opacity:1;
  color:rgba(229, 88, 0, var(--tw-text-opacity))
}

.root .child-list .child-link.is-active{
  background:linear-gradient(250deg, #FFD861 -0.65%, #FFCC32 100%)
}

.root .child-list .child-link .child-link-icon{
  display:inline-flex;
  align-items:center;
  width:18px;
  height:18px;
  font-size:18px;
  margin-right:12px
}

.root.is-off,.root.md-off,.root.lg-off{
  width:82px
}

@media (min-width: 1680px){
  .root.is-off, .root.md-off, .root.lg-off{
    width:92px
  }
}

.root.is-off .fixed-wrapper,.root.md-off .fixed-wrapper,.root.lg-off .fixed-wrapper{
  width:82px
}

@media (min-width: 1680px){
  .root.is-off .fixed-wrapper, .root.md-off .fixed-wrapper, .root.lg-off .fixed-wrapper{
    width:92px
  }
}

.root.is-off .scroll-wrapper,.root.md-off .scroll-wrapper,.root.lg-off .scroll-wrapper{
  padding-bottom:60px
}

.root.is-off .list,.root.md-off .list,.root.lg-off .list{
  width:82px
}

@media (min-width: 1680px){
  .root.is-off .list, .root.md-off .list, .root.lg-off .list{
    width:92px
  }
}

.root.is-off .list .list-item .item-link,.root.md-off .list .list-item .item-link,.root.lg-off .list .list-item .item-link{
  flex-direction:column;
  padding:12px 0;
  justify-content:center;
  color:#606370;
  border-top-width:0;
  cursor:pointer
}

.root.is-off .list .list-item .item-link:not(.has-children):hover, .root.md-off .list .list-item .item-link:not(.has-children):hover, .root.lg-off .list .list-item .item-link:not(.has-children):hover{
  --tw-text-opacity:1;
  color:rgba(229, 88, 0, var(--tw-text-opacity))
}

.root.is-off .list .list-item .item-link:not(.has-children):hover .link-icon,.root.md-off .list .list-item .item-link:not(.has-children):hover .link-icon,.root.lg-off .list .list-item .item-link:not(.has-children):hover .link-icon{
  color:currentColor
}

.root.is-off .list .list-item .item-link.is-active,.root.md-off .list .list-item .item-link.is-active,.root.lg-off .list .list-item .item-link.is-active{
  color:#1f2129
}

.root.is-off .list .list-item .item-link.is-active .link-icon,.root.md-off .list .list-item .item-link.is-active .link-icon,.root.lg-off .list .list-item .item-link.is-active .link-icon{
  color:currentColor
}

.root.is-off .list .list-item .item-link.has-children .link-icon,.root.md-off .list .list-item .item-link.has-children .link-icon,.root.lg-off .list .list-item .item-link.has-children .link-icon{
  display:inline-flex;
  align-items:center
}

.root.is-off .list .list-item .item-link.has-children .link-arrow,.root.md-off .list .list-item .item-link.has-children .link-arrow,.root.lg-off .list .list-item .item-link.has-children .link-arrow{
  display:inline-flex;
  align-items:center
}

.root .list .list-item .item-link .link-name.offset{
  color:currentColor;
  margin-right:-12px;
  font-size:inherit;
  line-height:inherit
}

@media (min-width: 1680px){
  .root .list .list-item .item-link .link-name.offset{
    margin-right:0px
  }
}

.root.is-off .list .list-item .item-link .link-icon,.root.md-off .list .list-item .item-link .link-icon,.root.lg-off .list .list-item .item-link .link-icon{
  width:20px;
  height:20px;
  font-size:20px;
  margin:0;
  color:#979aa8
}

.root.is-off .list .list-item .item-link .link-arrow,.root.md-off .list .list-item .item-link .link-arrow,.root.lg-off .list .list-item .item-link .link-arrow{
  margin-right:0;
  font-size:12px;
  line-height:12px
}

.root.is-off .child-list,.root.md-off .child-list,.root.lg-off .child-list{
  display:none
}

.root.is-off .rank-flag,.root.md-off .rank-flag,.root.lg-off .rank-flag{
  margin-bottom:20px
}

@media (min-width: 1680px){
  .root.lg-off{
    width:160px
  }
}

@media (min-width: 1680px){
  .root.lg-off .fixed-wrapper{
    width:160px
  }
}

@media (min-width: 1680px){
  .root.lg-off .scroll-wrapper{
    padding-bottom:0px
  }
}

@media (min-width: 1680px){
  .root.lg-off .list{
    width:160px
  }
}

@media (min-width: 1680px){
  .root.lg-off .list .list-item .item-link{
    flex-direction:row;
    justify-content:flex-start;
    padding-top:12px;
    padding-bottom:12px;
    padding-left:24px;
    --tw-text-opacity:1;
    color:rgba(31, 33, 41, var(--tw-text-opacity))
  }
}

@media (min-width: 1680px){
  .root.lg-off .list .list-item .item-link.is-active .link-icon{
    --tw-text-opacity:1;
    color:rgba(31, 33, 41, var(--tw-text-opacity))
  }
}

@media (min-width: 1680px){
  .root.lg-off .list .list-item .item-link.has-children{
    border-top-width:1px;
    --tw-text-opacity:1;
    color:rgba(151, 154, 168, var(--tw-text-opacity))
  }
}

@media (min-width: 1680px){
  .root.lg-off .list .list-item .item-link.has-children .link-icon{
    display:none
  }
}

@media (min-width: 1680px){
  .root.lg-off .list .list-item .item-link.has-children .link-arrow{
    display:none
  }
}

@media (min-width: 1680px){
  .root.lg-off .list .list-item .item-link .link-icon{
    height:18px;
    font-size:18px;
    margin-right:12px;
    --tw-text-opacity:1;
    color:rgba(31, 33, 41, var(--tw-text-opacity));
    width:18px
  }
}

@media (min-width: 1680px){
  .root.lg-off .list .list-item .item-link .link-arrow{
    margin-right:18px
  }
}

@media (min-width: 1680px){
  .root.lg-off .child-list{
    display:block
  }
}

@media (min-width: 1680px){
  .root.lg-off .rank-flag{
    margin-bottom:0px
  }
}

@media (min-width: 1440px){
  .root.md-off{
    width:160px
  }
}

@media (min-width: 1440px){
  .root.md-off .fixed-wrapper{
    width:160px
  }
}

@media (min-width: 1440px){
  .root.md-off .scroll-wrapper{
    padding-bottom:0px
  }
}

@media (min-width: 1440px){
  .root.md-off .list{
    width:160px
  }
}

@media (min-width: 1440px){
  .root.md-off .list .list-item .item-link{
    flex-direction:row;
    justify-content:flex-start;
    padding-top:12px;
    padding-bottom:12px;
    padding-left:24px;
    --tw-text-opacity:1;
    color:rgba(31, 33, 41, var(--tw-text-opacity))
  }
}

@media (min-width: 1440px){
  .root.md-off .list .list-item .item-link.is-active .link-icon{
    --tw-text-opacity:1;
    color:rgba(31, 33, 41, var(--tw-text-opacity))
  }
}

@media (min-width: 1440px){
  .root.md-off .list .list-item .item-link.has-children{
    border-top-width:1px;
    --tw-text-opacity:1;
    color:rgba(151, 154, 168, var(--tw-text-opacity))
  }
}

@media (min-width: 1440px){
  .root.md-off .list .list-item .item-link.has-children .link-icon{
    display:none
  }
}

@media (min-width: 1440px){
  .root.md-off .list .list-item .item-link.has-children .link-arrow{
    display:none
  }
}

@media (min-width: 1440px){
  .root.md-off .list .list-item .item-link .link-icon{
    height:18px;
    font-size:18px;
    margin-right:12px;
    --tw-text-opacity:1;
    color:rgba(31, 33, 41, var(--tw-text-opacity));
    width:18px
  }
}

@media (min-width: 1440px){
  .root.md-off .list .list-item .item-link .link-arrow{
    margin-right:18px
  }
}

@media (min-width: 1440px){
  .root.md-off .child-list{
    display:block
  }
}

@media (min-width: 1440px){
  .root.md-off .rank-flag{
    margin-bottom:0px
  }
}

.root.is-off .list .list-item .item-link.has-children{
  color:#606370
}

.root.none{
  display:none
}

.root.md-none{
  display:none
}

@media (min-width: 1440px){
  .root.md-none{
    display:block
  }
}

.root.fixed{
  display:block
}