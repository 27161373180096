* {
  margin: 0;
  padding: 0
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

html {
  min-height: 100%;
  min-height: 100vh
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  min-height: 100%;
  min-height: 100vh;
  color: #1a1a1a
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent
}

li,
ul {
  list-style: none
}

iframe {
  display: none
}

.fl {
  float: left
}

.fr {
  float: right
}

.line-2,
.line-3,
.line-4,
.line-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
  white-space: normal
}

.line-2 {
  -webkit-line-clamp: 2
}

.line-3 {
  -webkit-line-clamp: 3
}

.line-4 {
  -webkit-line-clamp: 4
}

.line-5 {
  -webkit-line-clamp: 5
}

.clearfix::after {
  content: ' ';
  display: block;
  clear: both;
  font-size: 0
}

.grid::after {
  content: ' ';
  display: block;
  clear: both
}

.grid-item {
  float: left
}

.line-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1F2129
}

a {
  -ms-transition: color 0.1s ease-in-out;
  -webki-transition: color 0.1s ease-in-out;
  transition: color 0.1s ease-in-out;
}

a:hover {
  color: #e65800
}