@import '@byted-motor/pc-components/dist/styles/variable.scss';
.modal-wrapper{
  z-index: 99999 !important;
}

.modal-wrapper-children{
  padding: 0 32px !important;
}

.modal-footer{
  width: 296px;
  height: 40px;
  line-height: 40px;
  background-color: #FFCC32;
  position: absolute;
  bottom: 20px;
}

.module-title{
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: start;
  margin-left: 16px;
}

.modal-body-children{
  height: 300px;
  color: #979AA8;
  font-size: 14px;
  line-height: 20px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  p{
    text-indent: 2em;
  }
  a{
    color:#1E3BB2;
  }
}

.modal-body-children::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}